import { z } from "zod";

import { Credentials, UserSchema, UserTokenSchema } from "~/services/api-schemas";
import { http } from "~/services/http";
import { setUserToken } from "~/services/session";

export async function fetchUserToken(credentials: Credentials) {
  const { data } = await http.post({
    path: "auth",
    body: credentials,
    schema: UserTokenSchema,
    no401Retry: true,
  });

  setUserToken(data);

  return data;
}

export async function fetchUser() {
  const { data } = await http.get({
    path: "current_user",
    schema: z.object({ user: UserSchema }),
  });

  return data.user;
}
